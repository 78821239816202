"use strict";

(function () {
  //Sub nav collapse control
  $(document).ready(function () {
    $("#jsSubNav").on("hide.bs.collapse", function () {
      $("#section-btn").html('Section menu <span class="fas fa-caret-down"></span>');
    });
    $("#jsSubNav").on("show.bs.collapse", function () {
      $("#section-btn").html('Section menu <span class="fas fa-caret-up"></span>');
    });
  });
}).call(void 0);