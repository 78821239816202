"use strict";

(function () {
  //Fadin and fadeout functions
  //Variable for length of time for fade
  var fadeTime;
  fadeTime = 1500;

  $.fn.fadeElementIn = function (elm) {
    $(elm).fadeIn(fadeTime);
    return this;
  };

  $.fn.fadeElementOut = function (elm) {
    $(elm).fadeOut(fadeTime);
    return this;
  };
}).call(void 0);