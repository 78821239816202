"use strict";

(function () {
  //Page scroll functionality
  //$(document).on('click', 'a[href^="#"]',-> event.preventDefault())
  //Variable for window width
  var fadeTime, scrolltime, windowHeightPositionMin, windowWidthMax, windowWidthMin;
  windowWidthMax = 1200;
  windowWidthMin = 767;
  windowHeightPositionMin = 600;
  scrolltime = 800;
  fadeTime = 1500;
  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, scrolltime);
  });
  $(document).scroll(function () {
    $.fn.backToTopControl();
  });
  $(window).resize(function () {
    $.fn.backToTopControl();
  });

  $.fn.positionCheck = function () {
    if ($(window).width() >= windowWidthMax || $(window).width() <= windowWidthMin) {
      if ($.fn.windowHeightPosition() >= windowHeightPositionMin) {
        return true;
      } else {
        return false;
      }
    }

    ;
  };

  $.fn.backToTopControl = function () {
    if ($.fn.positionCheck()) {
      $(".footer-back-top").fadeIn(fadeTime);
    } else {
      $(".footer-back-top").fadeOut(fadeTime);
    }
  };

  $.fn.windowHeightPosition = function () {
    return $('html, body').scrollTop();
  };
}).call(void 0);