"use strict";

(function () {
  $.fn.exists = function (b) {
    var a = [].slice.call(arguments, 1);

    if (this.length) {
      b.call(this, a);
    }

    return this;
  };
}).call(void 0);