"use strict";

(function () {
  //Service Worker Registration
  //https://developers.google.com/web/fundamentals/primers/service-workers/
  //02-01-2019
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker.register('../service-worker.js').then(function (registration) {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
      }, function (err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err);
      });
    });
  }

  ;
}).call(void 0);